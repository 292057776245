const RECAPTCHA_SITE_KEY = "6LetA7EiAAAAAEIdMgzlw5oMG5WIneEZmJRbeOD7";

/* load google's recaptcha JS async */
const d = window.document;
const gs = d.createElement("script");
gs.type="text/javascript";
gs.async=!0;
gs.src="https://www.google.com/recaptcha/api.js?badge=bottomleft&render=" + RECAPTCHA_SITE_KEY;
d.head.appendChild(gs);
/* end loading google JS */

window.hideCaptcha = (message = "random call") => {
  console.log('hideCaptcha triggered by', message);
  if (!window.grecaptcha || !window.grecaptcha.ready) {
    return setTimeout(() => {
      window.hideCaptcha(message + ', retry');
    }, 50);
  }

  window.avoShowCaptcha = false;
  window.grecaptcha.ready(function() {
    // hide badge from fixed place until we decide we're open
    const gb = document.getElementsByClassName("grecaptcha-badge")[0];
    gb.style.display = 'none';
  });
};
window.hideCaptcha("page load");

window.showCaptcha = (message = "random call", callback) => {
  console.log('showCaptcha triggered by', message);
  if (!window.grecaptcha || !window.grecaptcha.ready) {
    return setTimeout(() => {
      window.showCaptcha(message + ', retry', callback);
    }, 50);
  }

  window.avoShowCaptcha = true;
  window.grecaptcha.ready(function() {
    const gb = document.getElementsByClassName("grecaptcha-badge")[0];
    gb.style.display = 'block';
    if (callback) callback();
  });
}

window.setInterval(() => {
  const gb = document.getElementsByClassName("grecaptcha-badge")[0];
  if (!gb || !gb.style) return;

  const gbStyleDisplay = window.avoShowCaptcha ? 'block' : 'none';
  if (gb.style.display === gbStyleDisplay) return;

  gb.style.display = gbStyleDisplay;
}, 250);

window.getCaptcha = (action, callback) => {
  console.log('getCaptcha triggered by', action);

  if (!window.grecaptcha || !window.grecaptcha.ready) {
    return setTimeout(() => {
      window.getCaptcha(action, callback);
    }, 50);
  }

  grecaptcha.ready(function() {
    // Do the normal recaptcha things...
    grecaptcha.execute(RECAPTCHA_SITE_KEY, { action }).then(callback);
  });
};
